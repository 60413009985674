header{
	display: flex;
	height: 90px;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0.8;
	padding: 0 50px;
	background: $bgheader;
	z-index: 100;
	box-sizing: border-box;
	@media only screen and (max-width: $mobile-max){
		padding: 0 10px;
	}
	.logo{
	  	width: 300px;
	  	@media only screen and (max-width: $mobile-max){
			width: 200px;
		}
	  	img{
	  		width: 100%;
	  		height: auto;
	  	}
	}
	.menu{
		width: 43px;

		@media only screen and (max-width: $mobile-max){
			width: 37px;
		}
		img{
			width: 100%;
	  		height: auto;
		}
		&:hover{
			cursor: pointer;
		}
	}
}