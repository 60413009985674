.region-page{
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.wrapper{
		padding-top: 90px;
		height: 100%;
	}
}
.region-colum-left-wrap{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;

}

.region-colum-left-first{
	height: 52px;
}
.region-colum-left-title{
	@extend .map-left-into-title;
	padding-bottom: 0;
}
.region-colum-left-description{
	@extend .map-left-into-description; 
}
.grow-colum-centere{	
	padding: 0;
	align-self: stretch;
	height: auto;
	.region-colum-centre-wrap{
		position: relative;
		text-align: center;
		img{
			height: calc(100vh - 100px);
			//margin: 0 auto;
		}
	}
}
.region-colum-right-wrap{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.sub-menu{
	ul{
		padding: 0;
        margin: 0;
        list-style: none;
        font-size: 1.6rem;
        li{
            padding: 15px 0;
            border-top: 1px solid lighten($color-base-text,15%);
            &:first-child{
            	padding-top: 0;
                border-top: none;
            }
            &:last-child{
            	padding-bottom: 100px;
            }
        }
        a{
        	color: lighten($color-base-text,15%);
        	text-decoration: none;
        	&:hover{
        		color: $color-base-text;;
        	}             
    	}
	}
}
.region-map{
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	img{
		display: none;
		margin: 0 auto;
		height: calc(100vh - 100px);
		&:first-child{
			display: block;
		}
		&:not(:first-child){
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%);
		}
	}
}