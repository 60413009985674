nav{
    background: $bgheader;
    box-sizing: border-box;
    width: 320px;
    font-size: $font-size-nav;
    font-weight: 700;
    padding: 20px 50px 20px 20px;
    opacity: 0.8;
    @media only screen and (max-width: $mobile-max){
        width: 100%;
    }
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
            padding: 15px 0;
            border-top: 1px solid $color-base-text;
            &:first-child{
                border-top: none;
            }
        }
    }
    a{
        color: lighten($color-base-text,15%);
        text-decoration: none;
        &:hover{
            color: $color-base-text;
        }
        
    }
}
#sidebar {
    position: fixed;
    top: 90px;
    right: -500px;
    height: calc(100vh - 90px);
    z-index: 100;
    transition: all 0.3s;
    overflow-y: auto;
    &.active{
        right:0;
    }
}