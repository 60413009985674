.parthners-about{
	margin: 2rem 0;
	display: flex;
	width: 100%;
	overflow: hidden;
}
.partners-about-text{
 	width: 30%;
 	@media only screen and (max-width: $mobile-max){
        display: none;
    }
}
.parthner-slider{
	min-height: 220px;
}
.partner-slider-item{
	
	background: #e5ddd0;
	border: 10px solid #f9f6ee;
	padding: 20px;
	
	min-height: 230px;
	@media only screen and (min-width: 1340px) and (max-width: 1600px){
        min-height: 305px;
    }
	@media only screen and (min-width: 1025px) and (max-width: 1340px){
        min-height: 350px;
    }
    @media only screen and (min-width: 480px) and (max-width: 768px){
        min-height: 330px;
    }
}
.partners-about-slider{
	display: block;
	position: relative;
	width: 70%;
	@media only screen and (max-width: $mobile-max){
        width: 100%;
    }
}
.partner-slider-content, .partners-about-text-item{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	a{
		color: $color-base-text;
		text-decoration: none;
	}
}
.partners-about-text-item{
	font-size: $font-normal;
	font-weight: 700;
}
.partner-slider-content{
	div{
		height: 100%;
	}
}

/* page partners */
.partners-vertikal-list{
	margin: 2rem;
}
.partners-list-item{
	color: $light-text;
	line-height: 22px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 1.5rem;
	a{
		color: $light-text;
		text-decoration: none;
		&:hover{
			color: darken($light-text, 10%);
		}
	}
}