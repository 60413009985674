/* forms */
fieldset {
  padding: 0px;
  margin: 0;
  border: 0;
  width: 100%;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  font-size: 21px;
  line-height: inherit;
  color: #000;
  border: 0;
}
label {
  display: inline-block;
  max-width: 100%;
}
label .radio {
  margin-right: 15px;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding:  0.5rem;
  opacity: 0.65;
  transition: all 0.2s ease;
  font-size: $font-normal;
  color: $body-color;
  background: $bgcolor;
  background-image: none;
  border: 1px solid $color-home-menu;
  border-radius: none;
  opacity: 0.4;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  border: 1px solid $color-home-menu;
  outline: none;
  border-radius: none;
  color: $body-color;
  outline: none;
  opacity:  1;

}
input[type="search"] {
  box-sizing: border-box;
}
input[type="checkbox"] {
  
}

input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
input[type="submit"], input[type="reset"], button[type="submit"], button[type="reset"] {
  background:  $bgcolor;
  border: 1px solid $color-home-menu;
  color:  $body-color;
  opacity:  0.4;
  &:hover,
  &:focus{
    opacity: 1;
  }
}
select[multiple],
select[size] {
  height: auto;
}
option {
  
}

select {
  padding: 5px;
    font-size: $font-normal;
    color: $body-color;
    outline: none;
}
select:focus {
  
}
textarea,
select[multiple].form-control {
  height: auto;
}
.form-group{
  display:  block;
  box-sizing: border-box;
  width:  100%;
}
.form-vertical{
  display: grid;
  grid-template-columns: [form-left ] 250px [form-right] 400px ;
  grid-template-rows:  100px 100px 60px;
  grid-auto-flow: row;
  grid-gap:  1rem;
  justify-content: center;
  padding:  0.5rem;
  @media all and (max-width: 650px){
    grid-template-columns: 1fr;
    grid-template-rows:  80px 80px 200px 60px;
  }
  .form-name{
    grid-column: form-left;
    grid-row: 1 / 2;
    @media all and (max-width: 650px){
      grid-column: auto;
      grid-row: 1 / 2;
    }
  }
  .form-email{
    grid-column: form-left;
    grid-row: 2 / 3;
    @media all and (max-width: 650px){
      grid-column: auto;
      grid-row: 2 / 3;
    }
  }
  .form-message{
    grid-column: form-right;
    grid-row: 1 / 3;
    @media all and (max-width: 650px){
      grid-column: auto;
      grid-row: 3 / 4;
    }
  }
  .form-submit{
    grid-column: 1 / 3;
    grid-row:  3 / 3;
    @media all and (max-width: 650px){
      grid-column: auto;
    }
  }
  label{
    display: block;
    margin-bottom:  0.5rem;
  }
  input{
    border: none;
    border-bottom:  1px solid $color-home-menu;
  }
  input:focus, textarea:focus{
    outline: none;
  }
  input:focus{
    border: none;
    border-bottom:  1px solid $color-home-menu;
  }
  textarea{
    outline: none;
    border-radius: none;
    height:  90%;
  }
  button{
    grid-column: span 2;
    @media all and (max-width: 650px){
      grid-column: auto;
    }
  }
}