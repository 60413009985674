.cards-list{
	margin-top: 50px;
}
.faq-title{
	margin-top: 2rem;
}
.question-card{
	@extend .card;
	position: relative;
	&:last-child{
    	margin-bottom: 5vh;
  	}
  	.tranparent-block{
  		display: block;
  		position: absolute;
  		height: 50%;
  		width: 100%;
  		bottom: 0;
		background: linear-gradient(to top,  rgba(249,246,238,0.9) 10%,rgba(249,246,238,0.2) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#007db9e8',GradientType=0 );
  	}
}
.question-card-question{
	@extend .card-title;
	@media only screen and (max-width: $mobile-max){
    	text-align: left;
  	}
  	a{
  		text-decoration: none;
  	}
}
.question-card-answer{
	@extend  .card-text;
}
.add-form-button{
	display: inline-block;
	padding: 5px 8px;
	border-radius: 15px;
	background: #f9f6ee;
	border: 1px solid #2c1e1e;
	color: #676767;
	opacity: .4;
	cursor: pointer;
	&:hover,
  	&:focus{
    	opacity: 1;
  	}	
}
#add-question-form{
	margin-top: 30px;
	display:none;
}