.pre-logo-about{
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 2rem;
	@media only screen and (max-width: $mobile-max){
        justify-content: center;
        flex-wrap: wrap;
    }
}
.pre-logo-about-item{
	@media only screen and (max-width: $mobile-max){
		text-align: center;
        width: 50%
    }
	&:first-child{
		@media only screen and (max-width: $mobile-max){
        	width: 100%
    	}
	}
	img{
		max-width: 150px;
		max-height: 86px;
		@media only screen and (max-width: $mobile-max){
        	max-width: 120px;
    	}
	}
}
.about-envelope{
	margin: 2rem 0;
	text-align: center;
	img{
		width: 150px;
	}
}
main.about{
	img{
		display: block;
		margin: 1rem auto;
		width: 570px;
		@media only screen and (max-width: 600px){
        	width: 90%;
    	}
	}
}