.overlay {
  z-index: 1120;
  position: fixed;
  background: $overlay;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;  
  display: none;
}