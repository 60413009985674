.maps-page{
	.wrapper{
		padding-top: 90px;
		//height: calc(100% - 90px);
	}
}
.region-colum-left-maps{
	display:flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	height: 100%; 
}
.region-colum-left-maps-title{
	font-size: 1.6rem;
	line-height: 1.2em;
}
.maps-menu{
	display: flex;
	justify-content: left;
	ul{
		font-size: 1.6rem;
		line-height: none;
		margin: 0;
		padding:0;
		list-style: none;
		li.active{
        	color: $color-base-text;
        }
		li{
			color: lighten($color-base-text,25%);
            padding: 15px 0;
            border-bottom: 1px solid lighten($color-base-text,25%);
            &:last-child{
                border-bottom: none;
            }
            &:hover{
            	color: $color-base-text;
            	cursor: pointer;
            }
        }

	}
}
.grow-colum-centre-wrap{
	box-sizing: border-box;
	position: relative;
	display: block;
	min-height: 100%;
	overflow: hidden;
	
}
.abs{
	position:relative;
	min-height: 100%;
	min-width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	img{
		max-width: 100%;
		position: relative;
		display: none;
		opacity: 0;
		&:first-child{
			opacity: 1;
		}
	}
	img.active{
		display:block;
	}
}