.paginate{
	width: 100%;
	text-align: center;
	ul.pagination{
		margin-top: .5rem;
		margin-bottom: .5rem;
		display: flex;
		justify-content: center;
		padding-left: 0;
		list-style: none;
		border-radius: .25rem;
		a{
			text-decoration: none;

		}
		.page-link {
		    position: relative;
		    display: block;
		    padding: .5rem .75rem;
		    margin-left: -1px;
		    line-height: 1.25;
		    background-color: #fff;
		    border: 1px solid $color-paginate;
		    &:hover {
			    text-decoration: none;
			    background-color: $color-paginate;
			    border-color: $color-paginate;
			}
		    &:not(:disabled):not(.disabled) {
			    cursor: pointer;
			}
		}
		.page-item{
			&.disabled .page-link {
			    color: lighten($color-base-text, 50);
			    pointer-events: none;
			    // Opinionated: remove the "hand" cursor set previously for .page-link
			    cursor: auto;
		  	}
		  	&.active .page-link {
		  		z-index: 1;
			    color: $color-home-menu;
			    background-color: $color-paginate;
			    border-color: $color-home-menu;
			    pointer-events: none;
			    cursor: auto;
			 }
		}
	}
}