/* base */
*{
  outline:none;
}
html{
  height: 100%;
}
html,
body {
 // height: 100%;
  background-color: $bgcolor;
  margin:0;
  padding:0;
  font-family: $font-base;
}
body {
 color: $color-base-text;
 font-size: $font-normal;
 line-height: 1.5rem;
}

a{
  color: $color-base-text;
  transition: all 0.3s ease;
	&:hover{
      color:lighten($color-base-text,10%);
  	}
}
p{
  text-indent: 0;
  line-height: 1.2rem;
  margin: 0.25rem;
  a{
    text-decoration: underline;
  }
}
h1, h2, h3, h4, h5, h6{
  font-weight: 700;
  text-indent: 0;
}
h1{
  font-size: $font-normal*1.5;
  //margin:
}
h2{
  font-size: $font-normal*1.4;
}
h3{
  font-size: $font-normal*1.3;
}
h4{
  font-size: $font-normal*1.2;
}
h5{
  font-size: $font-normal*1.1;
  margin: 0 0 $font-normal*1.1;
}
h6{
  font-size: $font-normal;
  margin: 0 0 $font-normal;
}
div{
 box-sizing: border-box;
}

.wrapper{
  padding-top: 90px;
  width: 100%;
  height: 100%;
}
.container{
  padding: 0.5rem;
  padding-bottom: 5vh;
  width: 50%;
  margin: 0 auto;
  @media only screen and (max-width: $desktop-min){
      width: 100%;
  }
}
.row{
  display: flex;
}
.col-25{
  width: 25%;
  padding: 0.5rem;
}
.col-50{
  width: 50%;
  padding: 0.5rem;
}
.icon-block{
  text-align: left;
}
.icon-block-txt{
  a{
    text-decoration: none;
    color: lighten($color-base-text,25%);
    &:hover{
      color: $color-base-text;
    }
  }

}
.grow{
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 25% 1fr 25%;
  height: 100%;
  @media only screen and (min-width: 1921px){
      grid-template-columns: 20% 1fr 20%;
  }

}
.grow-colum-left, .grow-colum-right{
  padding: 0.5rem;
}
.grow-colum-left{
  padding-left: 50px;
}
.grow-colum-right{
  padding-right: 50px;
}
.grow-colum-centre{
  text-align: center;
  height: auto;
  align-self: stretch;
}
.page-title{
  margin-bottom: 2rem;
  text-align: center;
}
.card{
  
}
.card-wrapper{
  display: flex;
  @media only screen and (max-width: $mobile-max){
    flex-wrap: wrap;
  }
}
.card-title{
  font-size: $font-size-nav;
  font-weight: 700;
  line-height: 1.8rem;
  @media only screen and (max-width: $mobile-max){
    text-align: center;
  }
}
.card-body{
  font-size: $font-normal;
  font-weight: 400;
  line-height: 1.2rem;
  padding: 0 0.5rem;
  width: 75%;
  @media only screen and (max-width: $mobile-max){
    width: 100%;
  }
}
.card-cover{
  padding: 0 0.5rem;
  width: 25%;
  text-align: center;
  //width: 200px;
  @media only screen and (max-width: $mobile-max){
    width: 100%;
  }
  img{
    width: 100%;
    max-width: 200px;
    opacity: 0.8;
    &:hover{
      box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.6);
      opacity: 1;
    }
  }
}
.card-separator{
  margin: 5vh auto;
  width: 33%;
  height: 1px;
  background: $body-color;
  opacity: 0.6;
  &:last-child{
    display:none;
  }
}
.card-hidden{
  display:none;
}
.card-text{
  padding: 0.5rem 0;
}
.card-images{
  display: flex;
  flex-wrap: wrap;
  
  .card-images-item{
    padding: 0.5rem;
    text-align: center;
    img{
      height: 150px;
      width: auto;
      opacity: 0.8;
      @media only screen and (max-width: $mobile){
        width: 100%;
        height: auto;
      }
      &:hover{
        box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.6);
        opacity: 1;
      }
    }
  }
  
}
.main-content{
  img{
    display: block;
    margin: 10px auto;
    width: 75%;
    max-width: 450px;
  }
}
.center{
  text-align: center;
}
img.content-img{
  @media only screen and (max-width: 650px){
    max-width: 90%;
    height: auto;
  }
}