.home-page,
.region-page,
.page-map,
.maps-page,
{
	height: 100%;
}
.page{
	background-repeat:no-repeat;
	background-attachment:fixed;
}
.page .wrapper{
	min-height: 100vh;
}
.page .container{
	background: $bgcolor;
	min-height: 90vh;
}
.partners-page{
	background-repeat:no-repeat;
	background-attachment:fixed;
	background-position: 50% 90px;
	.wrapper{
		min-height: 100vh;
	}
}