.lightboxOverlay{
	background-color: $overlay;
	opacity: 0.9;
}
.lb-cancel {
	background: url(../images/loading.gif) no-repeat;
}
.lb-nav{
	a.lb-prev{
		background: url(../images/prev.png) left 48% no-repeat;
	}
}
.lb-nav{
	a.lb-next{
		background: url(../images/next.png) right 48% no-repeat;
	}
}
.lb-close {
	width: 30px;
	height: 30px;
	background: url(../images/close.svg) top right no-repeat;
}