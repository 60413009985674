.home-page,
.partners-page{
	background: url('../images/bghome.png') 100% 100% no-repeat $bgcolor;
	background-size:cover;
	header{
		background: $color-home-header;
	}
	nav{
		background: $color-home-nav;
		a{
			color: $light-text;
			&:hover{
				color: darken($light-text, 10%);
			}
		}
		ul{
			li{
				border-top: 1px solid $light-text;
				&:first-child{
                	border-top: none;
            	}
			}
		}
	}
}
.home-container{
	position: relative;
	width: 100%;
	height: 100%;
}
.home-container-about{
	display:flex;
	position: absolute;
	bottom: 8%;
	left: 10%;
	@media only screen and (max-width: $tablet-max){
        bottom: 5%;
		left: 5%;
    }
	a{
		color: $color-icon-home;
		text-decoration: none;
		font-weight: 700;
		font-size: $font-size-nav;
	}
}
.home-container-logo{
	width: 500px;
  	height: 365px;
  	margin: auto;
  	position: absolute;
  	top: 0; left: 0; bottom: 0; right: 0;
  	@media only screen and (max-width: $tablet-max){
        width: 300px;
  		height: 219px;
    }
    @media only screen and (max-width: $mobile-max){
        width: 200px;
  		height: 146px;
    }
  	img{
  		width: 100%;
  		height: auto;
  	}
}
.home-container-arows{
	width: 50px;
	height: 50px;
	position: absolute;
	top: calc(50% - 25px);
	right: - 25%;
	@media only screen and (max-width: $mobile-max){
        width: 40px;
  		height: 46px;
  		top: calc(50% - 20px);
    }
	img{
		width: 100%;
  		height: auto;
	}
}
.home-container-item:last-child{
	padding-left: 1.5rem;
}