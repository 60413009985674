@charset "UTF-8";
@import 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400&display=swap';
@import "vendor/normalize.css";
/* font-family */
/* color */
/* font-size */
/* base */
@import "vendor/lightbox.css";
@import "vendor/slick.css";
* {
  outline: none;
}

html {
  height: 100%;
}

html,
body {
  background-color: #f9f6ee;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

body {
  color: #4d4d4d;
  font-size: 16px;
  line-height: 1.5rem;
}

a {
  color: #4d4d4d;
  transition: all 0.3s ease;
}
a:hover {
  color: #676767;
}

p {
  text-indent: 0;
  line-height: 1.2rem;
  margin: 0.25rem;
}
p a {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  text-indent: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22.4px;
}

h3 {
  font-size: 20.8px;
}

h4 {
  font-size: 19.2px;
}

h5 {
  font-size: 17.6px;
  margin: 0 0 17.6px;
}

h6 {
  font-size: 16px;
  margin: 0 0 16px;
}

div {
  box-sizing: border-box;
}

.wrapper {
  padding-top: 90px;
  width: 100%;
  height: 100%;
}

.container {
  padding: 0.5rem;
  padding-bottom: 5vh;
  width: 50%;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
  }
}

.row {
  display: flex;
}

.col-25, .map-right-colum, .map-left-colum {
  width: 25%;
  padding: 0.5rem;
}

.col-50, .map-center-colum {
  width: 50%;
  padding: 0.5rem;
}

.icon-block {
  text-align: left;
}

.icon-block-txt a {
  text-decoration: none;
  color: #8d8d8d;
}
.icon-block-txt a:hover {
  color: #4d4d4d;
}

.grow {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 25% 1fr 25%;
  height: 100%;
}
@media only screen and (min-width: 1921px) {
  .grow {
    grid-template-columns: 20% 1fr 20%;
  }
}

.grow-colum-left, .grow-colum-right {
  padding: 0.5rem;
}

.grow-colum-left {
  padding-left: 50px;
}

.grow-colum-right {
  padding-right: 50px;
}

.grow-colum-centre {
  text-align: center;
  height: auto;
  align-self: stretch;
}

.page-title {
  margin-bottom: 2rem;
  text-align: center;
}

.card-wrapper {
  display: flex;
}
@media only screen and (max-width: 480px) {
  .card-wrapper {
    flex-wrap: wrap;
  }
}

.card-title, .question-card-question {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8rem;
}
@media only screen and (max-width: 480px) {
  .card-title, .question-card-question {
    text-align: center;
  }
}

.card-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2rem;
  padding: 0 0.5rem;
  width: 75%;
}
@media only screen and (max-width: 480px) {
  .card-body {
    width: 100%;
  }
}

.card-cover {
  padding: 0 0.5rem;
  width: 25%;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .card-cover {
    width: 100%;
  }
}
.card-cover img {
  width: 100%;
  max-width: 200px;
  opacity: 0.8;
}
.card-cover img:hover {
  box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.card-separator {
  margin: 5vh auto;
  width: 33%;
  height: 1px;
  background: #676767;
  opacity: 0.6;
}
.card-separator:last-child {
  display: none;
}

.card-hidden {
  display: none;
}

.card-text, .question-card-answer {
  padding: 0.5rem 0;
}

.card-images {
  display: flex;
  flex-wrap: wrap;
}
.card-images .card-images-item {
  padding: 0.5rem;
  text-align: center;
}
.card-images .card-images-item img {
  height: 150px;
  width: auto;
  opacity: 0.8;
}
@media only screen and (max-width: 380px) {
  .card-images .card-images-item img {
    width: 100%;
    height: auto;
  }
}
.card-images .card-images-item img:hover {
  box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.main-content img {
  display: block;
  margin: 10px auto;
  width: 75%;
  max-width: 450px;
}

.center {
  text-align: center;
}

@media only screen and (max-width: 650px) {
  img.content-img {
    max-width: 90%;
    height: auto;
  }
}

nav {
  background: #d9cdb3;
  box-sizing: border-box;
  width: 320px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 20px 50px 20px 20px;
  opacity: 0.8;
}
@media only screen and (max-width: 480px) {
  nav {
    width: 100%;
  }
}
nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
nav ul li {
  padding: 15px 0;
  border-top: 1px solid #4d4d4d;
}
nav ul li:first-child {
  border-top: none;
}
nav a {
  color: #737373;
  text-decoration: none;
}
nav a:hover {
  color: #4d4d4d;
}

#sidebar {
  position: fixed;
  top: 90px;
  right: -500px;
  height: calc(100vh - 90px);
  z-index: 100;
  transition: all 0.3s;
  overflow-y: auto;
}
#sidebar.active {
  right: 0;
}

header {
  display: flex;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.8;
  padding: 0 50px;
  background: #d9cdb3;
  z-index: 100;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  header {
    padding: 0 10px;
  }
}
header .logo {
  width: 300px;
}
@media only screen and (max-width: 480px) {
  header .logo {
    width: 200px;
  }
}
header .logo img {
  width: 100%;
  height: auto;
}
header .menu {
  width: 43px;
}
@media only screen and (max-width: 480px) {
  header .menu {
    width: 37px;
  }
}
header .menu img {
  width: 100%;
  height: auto;
}
header .menu:hover {
  cursor: pointer;
}

.home-page,
.partners-page {
  background: url("../images/bghome.png") 100% 100% no-repeat #f9f6ee;
  background-size: cover;
}
.home-page header,
.partners-page header {
  background: #5c4a4a;
}
.home-page nav,
.partners-page nav {
  background: #937f7f;
}
.home-page nav a,
.partners-page nav a {
  color: #e7ddce;
}
.home-page nav a:hover,
.partners-page nav a:hover {
  color: #d6c5ac;
}
.home-page nav ul li,
.partners-page nav ul li {
  border-top: 1px solid #e7ddce;
}
.home-page nav ul li:first-child,
.partners-page nav ul li:first-child {
  border-top: none;
}

.home-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.home-container-about {
  display: flex;
  position: absolute;
  bottom: 8%;
  left: 10%;
}
@media only screen and (max-width: 980px) {
  .home-container-about {
    bottom: 5%;
    left: 5%;
  }
}
.home-container-about a {
  color: #ad9e9e;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.2rem;
}

.home-container-logo {
  width: 500px;
  height: 365px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 980px) {
  .home-container-logo {
    width: 300px;
    height: 219px;
  }
}
@media only screen and (max-width: 480px) {
  .home-container-logo {
    width: 200px;
    height: 146px;
  }
}
.home-container-logo img {
  width: 100%;
  height: auto;
}

.home-container-arows {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  right: -25%;
}
@media only screen and (max-width: 480px) {
  .home-container-arows {
    width: 40px;
    height: 46px;
    top: calc(50% - 20px);
  }
}
.home-container-arows img {
  width: 100%;
  height: auto;
}

.home-container-item:last-child {
  padding-left: 1.5rem;
}

.home-page,
.region-page,
.page-map,
.maps-page {
  height: 100%;
}

.page {
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.page .wrapper {
  min-height: 100vh;
}

.page .container {
  background: #f9f6ee;
  min-height: 90vh;
}

.partners-page {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 90px;
}
.partners-page .wrapper {
  min-height: 100vh;
}

.page-map {
  background: url("../images/bgmap.jpg") 100% 100% repeat-y #f9f6ee;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

.scheme {
  height: auto;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.st0 {
  fill: #B8AE95;
  stroke: #FFFFFF;
  stroke-width: 1.0125;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #4E4E4E;
}

.region:hover {
  fill: #a58f63;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .map-content {
    flex-wrap: wrap;
  }
}

.map-left-colum {
  min-width: 280px;
  padding-left: 50px;
}
@media only screen and (max-width: 768px) {
  .map-left-colum {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) {
  .map-right-colum {
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  .map-center-colum {
    width: 75%;
  }
}
@media only screen and (max-width: 768px) {
  .map-center-colum {
    width: 100%;
  }
}

.map-left-into {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
}

.map-left-into-title, .region-colum-left-title {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.5em;
  padding-bottom: 5vw;
}
@media only screen and (max-width: 768px) {
  .map-left-into-title, .region-colum-left-title {
    font-size: 1.8rem;
    text-align: center;
  }
}

.map-left-into-description, .region-colum-left-description {
  font-size: 1.6rem;
  line-height: 1.2em;
  padding-bottom: 1.6rem;
}
@media only screen and (max-width: 768px) {
  .map-left-into-description, .region-colum-left-description {
    font-size: 1.4rem;
  }
}

.region-page {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.region-page .wrapper {
  padding-top: 90px;
  height: 100%;
}

.region-colum-left-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.region-colum-left-first {
  height: 52px;
}

.region-colum-left-title {
  padding-bottom: 0;
}

.grow-colum-centere {
  padding: 0;
  align-self: stretch;
  height: auto;
}
.grow-colum-centere .region-colum-centre-wrap {
  position: relative;
  text-align: center;
}
.grow-colum-centere .region-colum-centre-wrap img {
  height: calc(100vh - 100px);
}

.region-colum-right-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sub-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 1.6rem;
}
.sub-menu ul li {
  padding: 15px 0;
  border-top: 1px solid #737373;
}
.sub-menu ul li:first-child {
  padding-top: 0;
  border-top: none;
}
.sub-menu ul li:last-child {
  padding-bottom: 100px;
}
.sub-menu ul a {
  color: #737373;
  text-decoration: none;
}
.sub-menu ul a:hover {
  color: #4d4d4d;
}

.region-map {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.region-map img {
  display: none;
  margin: 0 auto;
  height: calc(100vh - 100px);
}
.region-map img:first-child {
  display: block;
}
.region-map img:not(:first-child) {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.maps-page .wrapper {
  padding-top: 90px;
}

.region-colum-left-maps {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.region-colum-left-maps-title {
  font-size: 1.6rem;
  line-height: 1.2em;
}

.maps-menu {
  display: flex;
  justify-content: left;
}
.maps-menu ul {
  font-size: 1.6rem;
  line-height: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
.maps-menu ul li.active {
  color: #4d4d4d;
}
.maps-menu ul li {
  color: #8d8d8d;
  padding: 15px 0;
  border-bottom: 1px solid #8d8d8d;
}
.maps-menu ul li:last-child {
  border-bottom: none;
}
.maps-menu ul li:hover {
  color: #4d4d4d;
  cursor: pointer;
}

.grow-colum-centre-wrap {
  box-sizing: border-box;
  position: relative;
  display: block;
  min-height: 100%;
  overflow: hidden;
}

.abs {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.abs img {
  max-width: 100%;
  position: relative;
  display: none;
  opacity: 0;
}
.abs img:first-child {
  opacity: 1;
}
.abs img.active {
  display: block;
}

/* forms */
fieldset {
  padding: 0px;
  margin: 0;
  border: 0;
  width: 100%;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  font-size: 21px;
  line-height: inherit;
  color: #000;
  border: 0;
}

label {
  display: inline-block;
  max-width: 100%;
}

label .radio {
  margin-right: 15px;
}

textarea,
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color] {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  opacity: 0.65;
  transition: all 0.2s ease;
  font-size: 16px;
  color: #676767;
  background: #f9f6ee;
  background-image: none;
  border: 1px solid #2c1e1e;
  border-radius: none;
  opacity: 0.4;
}

textarea:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=color]:focus {
  border: 1px solid #2c1e1e;
  outline: none;
  border-radius: none;
  color: #676767;
  outline: none;
  opacity: 1;
}

input[type=search] {
  box-sizing: border-box;
}

input[type=file] {
  display: block;
}

input[type=range] {
  display: block;
  width: 100%;
}

input[type=submit], input[type=reset], button[type=submit], button[type=reset] {
  background: #f9f6ee;
  border: 1px solid #2c1e1e;
  color: #676767;
  opacity: 0.4;
}
input[type=submit]:hover, input[type=submit]:focus, input[type=reset]:hover, input[type=reset]:focus, button[type=submit]:hover, button[type=submit]:focus, button[type=reset]:hover, button[type=reset]:focus {
  opacity: 1;
}

select[multiple],
select[size] {
  height: auto;
}

select {
  padding: 5px;
  font-size: 16px;
  color: #676767;
  outline: none;
}

textarea,
select[multiple].form-control {
  height: auto;
}

.form-group {
  display: block;
  box-sizing: border-box;
  width: 100%;
}

.form-vertical {
  display: grid;
  grid-template-columns: [form-left] 250px [form-right] 400px;
  grid-template-rows: 100px 100px 60px;
  grid-auto-flow: row;
  grid-gap: 1rem;
  justify-content: center;
  padding: 0.5rem;
}
@media all and (max-width: 650px) {
  .form-vertical {
    grid-template-columns: 1fr;
    grid-template-rows: 80px 80px 200px 60px;
  }
}
.form-vertical .form-name {
  grid-column: form-left;
  grid-row: 1/2;
}
@media all and (max-width: 650px) {
  .form-vertical .form-name {
    grid-column: auto;
    grid-row: 1/2;
  }
}
.form-vertical .form-email {
  grid-column: form-left;
  grid-row: 2/3;
}
@media all and (max-width: 650px) {
  .form-vertical .form-email {
    grid-column: auto;
    grid-row: 2/3;
  }
}
.form-vertical .form-message {
  grid-column: form-right;
  grid-row: 1/3;
}
@media all and (max-width: 650px) {
  .form-vertical .form-message {
    grid-column: auto;
    grid-row: 3/4;
  }
}
.form-vertical .form-submit {
  grid-column: 1/3;
  grid-row: 3/3;
}
@media all and (max-width: 650px) {
  .form-vertical .form-submit {
    grid-column: auto;
  }
}
.form-vertical label {
  display: block;
  margin-bottom: 0.5rem;
}
.form-vertical input {
  border: none;
  border-bottom: 1px solid #2c1e1e;
}
.form-vertical input:focus, .form-vertical textarea:focus {
  outline: none;
}
.form-vertical input:focus {
  border: none;
  border-bottom: 1px solid #2c1e1e;
}
.form-vertical textarea {
  outline: none;
  border-radius: none;
  height: 90%;
}
.form-vertical button {
  grid-column: span 2;
}
@media all and (max-width: 650px) {
  .form-vertical button {
    grid-column: auto;
  }
}

.alert-form {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

/* tabs */
.bibliography-tabs {
  margin-bottom: 50px;
}

ul.tabs {
  line-height: normal;
  list-style: none;
  white-space: nowrap;
  display: flex;
  font-size: 1.25rem;
  border-bottom: 1px solid #8d8d8d;
}
@media only screen and (max-width: 550px) {
  ul.tabs {
    padding-left: 0;
  }
}
ul.tabs li {
  background: #f9f6ee;
  display: block;
  padding: 0.5em 1em;
}
ul.tabs li a {
  color: #8d8d8d;
  text-decoration: none;
  padding: 0.5em 1em;
}
ul.tabs li a:hover {
  color: #4d4d4d;
}
@media only screen and (max-width: 550px) {
  ul.tabs li a {
    padding: 0.3em 0.5em;
  }
}
@media screen and (max-width: 550px) {
  ul.tabs li {
    padding: 0.3em 0;
  }
}
ul.tabs li.active a {
  color: #4d4d4d;
  border: 1px solid #8d8d8d;
  border-bottom: 2px solid #f9f6ee;
}
@media screen and (width: 550px) {
  ul.tabs {
    font-size: 1rem;
  }
}

.cards-list {
  margin-top: 50px;
}

.faq-title {
  margin-top: 2rem;
}

.question-card {
  position: relative;
}
.question-card:last-child {
  margin-bottom: 5vh;
}
.question-card .tranparent-block {
  display: block;
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  background: linear-gradient(to top, rgba(249, 246, 238, 0.9) 10%, rgba(249, 246, 238, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1e5799", endColorstr="#007db9e8",GradientType=0 );
}

@media only screen and (max-width: 480px) {
  .question-card-question {
    text-align: left;
  }
}
.question-card-question a {
  text-decoration: none;
}

.add-form-button {
  display: inline-block;
  padding: 5px 8px;
  border-radius: 15px;
  background: #f9f6ee;
  border: 1px solid #2c1e1e;
  color: #676767;
  opacity: 0.4;
  cursor: pointer;
}
.add-form-button:hover, .add-form-button:focus {
  opacity: 1;
}

#add-question-form {
  margin-top: 30px;
  display: none;
}

.lightboxOverlay {
  background-color: #251f1f;
  opacity: 0.9;
}

.lb-cancel {
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav a.lb-prev {
  background: url(../images/prev.png) left 48% no-repeat;
}

.lb-nav a.lb-next {
  background: url(../images/next.png) right 48% no-repeat;
}

.lb-close {
  width: 30px;
  height: 30px;
  background: url(../images/close.svg) top right no-repeat;
}

.video {
  max-width: 640px;
  margin-bottom: 10px;
}

.relation {
  position: relative;
}

.relation-ratio {
  padding-top: 56.25%;
  /* 16X9 */
  height: 0px;
}

.relation-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

video[poster] {
  height: 100%;
  width: 100%;
}

.video-center {
  max-width: 640px;
  margin: 20px auto;
}

.pre-logo-about {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  .pre-logo-about {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 480px) {
  .pre-logo-about-item {
    text-align: center;
    width: 50%;
  }
}
@media only screen and (max-width: 480px) {
  .pre-logo-about-item:first-child {
    width: 100%;
  }
}
.pre-logo-about-item img {
  max-width: 150px;
  max-height: 86px;
}
@media only screen and (max-width: 480px) {
  .pre-logo-about-item img {
    max-width: 120px;
  }
}

.about-envelope {
  margin: 2rem 0;
  text-align: center;
}
.about-envelope img {
  width: 150px;
}

main.about img {
  display: block;
  margin: 1rem auto;
  width: 570px;
}
@media only screen and (max-width: 600px) {
  main.about img {
    width: 90%;
  }
}

.parthners-about {
  margin: 2rem 0;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.partners-about-text {
  width: 30%;
}
@media only screen and (max-width: 480px) {
  .partners-about-text {
    display: none;
  }
}

.parthner-slider {
  min-height: 220px;
}

.partner-slider-item {
  background: #e5ddd0;
  border: 10px solid #f9f6ee;
  padding: 20px;
  min-height: 230px;
}
@media only screen and (min-width: 1340px) and (max-width: 1600px) {
  .partner-slider-item {
    min-height: 305px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1340px) {
  .partner-slider-item {
    min-height: 350px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .partner-slider-item {
    min-height: 330px;
  }
}

.partners-about-slider {
  display: block;
  position: relative;
  width: 70%;
}
@media only screen and (max-width: 480px) {
  .partners-about-slider {
    width: 100%;
  }
}

.partner-slider-content, .partners-about-text-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.partner-slider-content a, .partners-about-text-item a {
  color: #4d4d4d;
  text-decoration: none;
}

.partners-about-text-item {
  font-size: 16px;
  font-weight: 700;
}

.partner-slider-content div {
  height: 100%;
}

/* page partners */
.partners-vertikal-list {
  margin: 2rem;
}

.partners-list-item {
  color: #e7ddce;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.partners-list-item a {
  color: #e7ddce;
  text-decoration: none;
}
.partners-list-item a:hover {
  color: #d6c5ac;
}

.overlay {
  z-index: 1120;
  position: fixed;
  background: #251f1f;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
}

.paginate {
  width: 100%;
  text-align: center;
}
.paginate ul.pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.paginate ul.pagination a {
  text-decoration: none;
}
.paginate ul.pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.paginate ul.pagination .page-link:hover {
  text-decoration: none;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.paginate ul.pagination .page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.paginate ul.pagination .page-item.disabled .page-link {
  color: #cdcdcd;
  pointer-events: none;
  cursor: auto;
}
.paginate ul.pagination .page-item.active .page-link {
  z-index: 1;
  color: #2c1e1e;
  background-color: #dee2e6;
  border-color: #2c1e1e;
  pointer-events: none;
  cursor: auto;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../imagesajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}