.page-map{
	background: url('../images/bgmap.jpg') 100% 100% repeat-y $bgcolor;
	background-size:cover;
	background-attachment: fixed;
	min-height: 100%
}
.scheme{
	height: auto;
	width: 100%;
	margin: 0 auto;
	position: relative;
}
.st0{
	fill:#B8AE95;
	stroke:#FFFFFF;
	stroke-width:1.0125;
	stroke-miterlimit:10;
}
.st1{
	fill:#4E4E4E;
}
.region:hover{
	fill: #a58f63;
	cursor: pointer;
}
.map-content{
	@media only screen and (max-width: $tablet){
		flex-wrap: wrap;
	}
	
}
.map-left-colum{
	@extend .col-25;
	min-width: 280px;
	padding-left: 50px;
	@media only screen and (max-width: $tablet){
		width: 100%;
	}
}
.map-right-colum{
	@extend .col-25;
	@media only screen and (max-width: $tablet-max){
		display: none;
	}
}
.map-center-colum{
	@extend .col-50;
	@media only screen and (max-width: $tablet-max){
		width: 75%;
	}
	@media only screen and (max-width: $tablet){
		width: 100%;
	}
}
.map-left-into{
	width: 100%;
	height: 100%;
	display: flex;
	padding: 20px;
	flex-direction: column;
	justify-content: flex-end;
}
.map-left-into-title{
	font-size: 2.2rem;
	font-weight: 700;
	text-align: left;
	line-height: 1.5em;
	padding-bottom: 5vw;
	@media only screen and (max-width: $tablet){
		font-size: 1.8rem;
		text-align: center;
	}
}
.map-left-into-description{
	font-size: 1.6rem;
	line-height: 1.2em;
	padding-bottom: 1.6rem;
	@media only screen and (max-width: $tablet){
		font-size: 1.4rem;
	}
}