.video{
    max-width: 640px;
    margin-bottom: 10px;
}
.relation{
    position: relative;
}
.relation-ratio{
    padding-top: 56.25%; /* 16X9 */
    height: 0px;
}
.relation-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
video[poster] {
    height: 100%;
    width: 100%;
}
.video-center{
    max-width: 640px;
    margin: 20px auto;
}