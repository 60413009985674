/* tabs */
.bibliography-tabs{
	margin-bottom: 50px;
}
ul.tabs{
	line-height: normal;
	list-style: none;
	white-space: nowrap;
	display: flex;
	font-size: 1.25rem;
	border-bottom: 1px solid lighten($color-base-text,25%);
	@media only screen and (max-width: 550px) {
    	padding-left: 0;
  	}	
	li{
		background: $bgcolor;
		display: block;
		padding: 0.5em 1em;
		a{	
			color: lighten($color-base-text,25%);		
			text-decoration: none;
			padding: 0.5em 1em;	
			&:hover{
				color: $color-base-text;
			}
			@media only screen and (max-width: 550px) {
    			padding: 0.3em 0.5em;
  			}		
		}
		@media screen and (max-width: 550px) {
    		padding: 0.3em 0;
  		}
	}
	li.active{
		a{
			color: $color-base-text;
			border: 1px solid lighten($color-base-text,25%);
			border-bottom: 2px solid $bgcolor;
		}
	}
	@media screen and(width: 550px){
		font-size: 1rem
	}
}